import axios from "axios";
import cookiesManipulator from "services/utils/cookiesManipulator";

// const API_URL = process.env.REACT_APP_API_URL;

const API_URL = "https://api.ocr.platform.xane.ai/dev";

const headers = {
  // "x-origin": 1,
  // "x-version": 1,
  // "x-device-id": "device",
  // "x-platform": 1,
  // "Content-Type": "application/json",
};

const requestApi = async (resourcePath, method, params) => {
  // const url = `/${resourcePath}`; // for mirage
  const url = `${API_URL}/${resourcePath}`;

  headers["x-auth"] = await cookiesManipulator.getAuth().token;
  headers["x-identity"] = await cookiesManipulator.getAuth().identity;

  if (resourcePath === "/auths/login") {
    delete headers["x-auth"];
  }
  let response;
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    response = await axios({ url, method, data: params, headers });
    return response.data;
  }
  response = await axios({ url, method, headers });
  return response.data;
};

export default requestApi;

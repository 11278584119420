/* eslint-disable import/no-unresolved */
/** 
  All of the routes for the kai App are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  layouts
import { lazy, Suspense } from "react";
import ErrorBoundary from "Components/ErrorBoundary";
import Icon from "@mui/material/Icon";
import Loader from "Components/ProgressBar/Loader";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation, Navigate } from "react-router-dom";
import cookiesManipulator from "services/utils/cookiesManipulator";
import LinearProgress from "Components/ProgressBar/LinearProgress";

const Auth = lazy(() => import("Containers/Auth"));
const Home = lazy(() => import("Containers/Home"));
const Camera = lazy(() => import("Containers/Camera"));
const Result = lazy(() => import("Containers/Result"));
const Recents = lazy(() => import("Containers/Recents"));
const Account = lazy(() => import("Containers/Account"));
const FeatureType = lazy(() => import("Containers/FeatureType"));
const Voice = lazy(() => import("Containers/Voice"));
const Chat = lazy(() => import("Containers/Chat"));
const Results = lazy(() => import("Containers/Results"));
const InputSearch = lazy(() => import("Containers/InputSearch"));
// const TemplateList = lazy(() => import("containers/app/TemplateList"));
// const FileList = lazy(() => import("containers/app/FileList"));
// const Annotation = lazy(() => import("containers/app/Annotation"));
// const AddFiles = lazy(() => import("containers/app/AddFiles"));

// const locationStar = useLocation();

function LazyAuth(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Auth {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyHome(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Home {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyVoice(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Voice {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyChat(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Chat {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyInputSearch(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <InputSearch {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyResults(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Results {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyFeatureType(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <FeatureType {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyCamera(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Camera {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyResult(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Result {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyRecents(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Recents {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyAccount(props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ErrorBoundary>
        <Account {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

const routes = [
  {
    type: "collapse",
    name: "auth",
    key: "auth",
    icon: <Icon fontSize="small">auth</Icon>,
    route: `/login`,
    layout: "auth",
    component: <LazyAuth />,
  },
  {
    type: "collapse",
    name: "home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: `/home`,
    layout: "app",
    component: <LazyHome />,
  },
  {
    type: "collapse",
    name: "voice",
    key: "voice",
    icon: <Icon fontSize="small">voice</Icon>,
    route: `/voice`,
    layout: "app",
    component: <LazyVoice />,
  },
  {
    type: "collapse",
    name: "chat",
    key: "chat",
    icon: <Icon fontSize="small">chat</Icon>,
    route: `/chat`,
    layout: "app",
    component: <LazyChat />,
  },
  {
    type: "collapse",
    name: "inputsearch",
    key: "inputsearch",
    icon: <Icon fontSize="small">inputsearch</Icon>,
    route: `/inputsearch`,
    layout: "app",
    component: <LazyInputSearch />,
  },
  {
    type: "collapse",
    name: "results",
    key: "results",
    icon: <Icon fontSize="small">results</Icon>,
    route: `/results`,
    layout: "app",
    component: <LazyResults />,
  },
  {
    type: "collapse",
    name: "featureType",
    key: "featureType",
    icon: <Icon fontSize="small">FeatureType</Icon>,
    route: `/featureType`,
    layout: "app",
    component: <LazyFeatureType />,
  },
  {
    type: "collapse",
    name: "camera",
    key: "camera",
    icon: <Icon fontSize="small">auth</Icon>,
    route: `/camera`,
    layout: "app",
    component: <LazyCamera />,
  },
  {
    type: "collapse",
    name: "result",
    key: "result",
    icon: <Icon fontSize="small">auth</Icon>,
    route: `/result`,
    layout: "app",
    component: <LazyResult />,
  },
  {
    type: "collapse",
    name: "recents",
    key: "recents",
    icon: <Icon fontSize="small">recents</Icon>,
    route: `/recents`,
    layout: "app",
    component: <LazyRecents />,
  },
  {
    type: "collapse",
    name: "account",
    key: "account",
    icon: <Icon fontSize="small">account</Icon>,
    route: `/account`,
    layout: "app",
    component: <LazyAccount />,
  },
];

export function RequireAuth({ children }) {
  const authed = !!cookiesManipulator.getAuth().token;
  let isCreditsOver = false;
  if (cookiesManipulator.getAuth().user) {
    const { user, inference_count } = cookiesManipulator.getAuth();

    isCreditsOver = Number(user.credits) === Number(inference_count);
  }

  const location = useLocation();
  const notAllowedPaths = ["/app/camera", "/app/result"];

  return authed === true ||
    isCreditsOver === false ||
    notAllowedPaths.includes(location.pathname) === false ? (
    children
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
}
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default routes;

/* eslint-disable camelcase */
import { createSlice, current } from "@reduxjs/toolkit";

import STATUSES from "data/constants/STATUSES";
import cookiesManipulator from "services/utils/cookiesManipulator";
import { readFromLocalStorage, writeToLocalStorage } from "services/utils/localStorage";
import { getFiles, postFiles, deleteFile } from "store/actions/files";

const initialState = {
  currentRequestId: undefined,
  currentFileBuffer: null,
  status: STATUSES.IDLE,
  inference_count: cookiesManipulator.getAuth().inference_count,
  files: [],
  fileUploadStats: null,
  selectedFile: readFromLocalStorage("selectedFile"),
  errorMessage: null,
};

export const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileUploadStats(state, action) {
      state.fileUploadStats = action.payload;
    },
    setCurrentFileBuffer(state, action) {
      state.currentFileBuffer = action.payload;
    },
    clearFileErrorState(state) {
      state.status = STATUSES.IDLE;
    },
    setSelectedFile(state, action) {
      state.selectedFile = action.payload;
      writeToLocalStorage("selectedFile", action.payload);
    },
    updateInferenceCount(state) {
      const { inference_count } = cookiesManipulator.getAuth();
      cookiesManipulator.updateValue("inference_count", inference_count + 1);
      state.inference_count = inference_count + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        const { requestId } = action.meta;

        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { files } = action.payload;
          state.status = STATUSES.IDLE;
          state.files = files;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(postFiles.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postFiles.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { file } = action.payload;
          state.status = STATUSES.IDLE;
          state.selectedFile = file;
          writeToLocalStorage("selectedFile", file);
          state.currentFileBuffer = null;
          state.files = [...current(state.files), file];
          state.currentRequestId = undefined;
          const { inference_count } = cookiesManipulator.getAuth();
          cookiesManipulator.updateValue("inference_count", Number(inference_count) + 1);
          state.inference_count = Number(inference_count) + 1;
        }
      })
      .addCase(postFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(deleteFile.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { file } = action.payload;

          const fileIndex = state.files
            ? current(state.files).findIndex((obj) => obj.id === file.id)
            : -1;

          if (fileIndex > -1) {
            state.files.splice(fileIndex, 1);
          }
          state.status = STATUSES.IDLE;

          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { setFileUploadStats, setSelectedFile, setCurrentFileBuffer, clearFileErrorState } =
  fileSlice.actions;

export default fileSlice.reducer;

/* eslint-disable import/no-unresolved */
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import themeLight from "assets/themeLight";
import routes, { RequireAuth } from "routes/routes";
import { useEffect, useState } from "react";
import cookiesManipulator from "services/utils/cookiesManipulator";
import Dispatcher from "store/Dispatcher";
import detectPlatform from "services/utils/detectPlatform";
import { getIdentity } from "store/actions/auths";
import "style/app.css";
import BottomNavigationComp from "Components/BottomNavigation/BottomNavigationComp";
import getBrowserFingerprint from "get-browser-fingerprint";
import PWAPrompt from "react-ios-pwa-prompt";

function RoutesContainer({ children }) {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const BottomNavigationPaths = [
    "/app/camera",
    "/app/voice",
    "/app/inputsearch",
    "/app/featureType",
  ];
  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);
  const appThemeLight = createTheme(themeLight);

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);
  return (
    <ThemeProvider theme={appThemeLight}>
      <Container
        fixed
        sx={{ padding: "0", height: { innerHeight } }}
        style={{ height: innerHeight }}
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <CssBaseline />
        <Routes location={displayLocation}>{children}</Routes>
      </Container>
      {/* 
      {BottomNavigationPaths.includes(location.pathname) ? (
        <BottomNavigationComp BottomNavigationPaths={BottomNavigationPaths} />
      ) : null}

      */}
      {location.pathname === "/app/results" && (
        <BottomNavigationComp BottomNavigationPaths={BottomNavigationPaths} />
      )}
    </ThemeProvider>
  );
}

export default function App() {
  <PWAPrompt
    promptOnVisit={1}
    timesToShow={3}
    copyClosePrompt="Close"
    permanentlyHideOnDismiss={false}
  />;
  useEffect(() => {
    (async () => {
      const browserFingerprint = await getBrowserFingerprint({
        enableWebgl: true,
      });
      // const authed = !!cookiesManipulator.getAuth().identity;
      // if (authed === false)
      //  {
      const body = {
        // platform: detectPlatform(),
        identity: browserFingerprint,
      };
      await Dispatcher(getIdentity, body);
      // }
    })();
    // 695119685;
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.layout + route.route}
            element={
              route.layout === "auth" ? (
                route.component
              ) : (
                <RequireAuth>{route.component}</RequireAuth>
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <RoutesContainer>
      {getRoutes(routes)}
      <Route path="/" element={<Navigate to="/app/featureType" />} />
    </RoutesContainer>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import { Divider, Paper } from "@mui/material";
import {
  Home,
  Person,
  CameraAltOutlined,
  KeyboardVoiceOutlined,
  ForumOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

export default function BottomNavigationComp({ BottomNavigationPaths }) {
  const [value, setValue] = React.useState(1);
  const Location = useLocation();
  const Navigate = useNavigate();

  const bottomNav = [
    {
      icon: <CameraAltOutlined />,
      label: "Camera",
      navigateTo: BottomNavigationPaths[0],
    },
    {
      icon: <KeyboardVoiceOutlined sx={{ color: "#091e50" }} />,
      label: "Voice",
      navigateTo: BottomNavigationPaths[1],
    },
    {
      icon: <ForumOutlined />,
      label: "Chat",
      navigateTo: BottomNavigationPaths[2],
    },
    {
      icon: <Home />,
      label: "Home",
      navigateTo: BottomNavigationPaths[3],
    },
  ];

  React.useEffect(() => {
    bottomNav.forEach((item, index) => {
      if (Location.pathname.includes(item.label.toLowerCase())) {
        setValue(index);
      }
    });
  }, [Location.pathname]);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 10,
        left: 7,
        right: 7,
        backgroundColor: "info.light",
        // border: "1px solid red",
        borderRadius: "8px",
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{ backgroundColor: "transparent" }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {bottomNav.map((element) => (
          <BottomNavigationAction
            key={element.label}
            label={element.label}
            icon={element.icon}
            onClick={() => Location.pathname !== element.navigateTo && Navigate(element.navigateTo)}
            sx={{ color: "#091e50", bgColor: "#091e50" }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

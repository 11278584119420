import Cookies from "universal-cookie";

const cookies = new Cookies();
const expireDate = new Date();
expireDate.setFullYear(expireDate.getFullYear() + 1);

const options = {
  path: "/",
  secure: true,
  domain: window.location.hostname,
  expires: expireDate,
};
const cookiesManipulator = {
  setAuth: (data) => {
    const { session, user, identity } = data;
    if (session && user) {
      cookies.set("token", session.token, options);
      cookies.set("user", user, options);
    }
    if (identity) {
      cookies.set("identity", identity, options);
      cookies.set("user", user, options);
      cookies.set("inference_count", 0, options);
    }
  },
  updateValue: (cookiesName, value) => cookies.set(cookiesName, value, options),
  removeAuth: () => {
    cookies.remove("token", options);
    cookies.set("user", { credits: 3 }, options);
    cookies.remove("role", options);
  },
  getAuth: () => ({
    token: cookies.get("token", options),
    identity: cookies.get("identity", options),
    user: cookies.get("user", options),
    inference_count: cookies.get("inference_count", options),
  }),
};

export default cookiesManipulator;

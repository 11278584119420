import store from "store/store";

const Dispatcher = async (action, data = {}, async = true, showAlert = false) => {
  if (async) {
    try {
      const originalPromiseResult = await store.dispatch(action(data)).unwrap();
      if (originalPromiseResult) {
        return originalPromiseResult;
      }
    } catch (rejectedValueOrSerializedError) {
      if (showAlert) {
        const errorMessage =
          typeof rejectedValueOrSerializedError === "object"
            ? rejectedValueOrSerializedError.message
            : rejectedValueOrSerializedError;
        alert(errorMessage);
      } else {
        return rejectedValueOrSerializedError;
      }
    }
  } else {
    store.dispatch(action(data));
  }

  return null;
};

export default Dispatcher;
